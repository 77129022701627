import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import WomensHealthAndChildren from '../components/WomensHealthAndChildren';

function WomensHealthAndChildrenPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Wellspring BCST`, `Wellspring Cranio`, `Wellspring Biodynamic Craniosacral Therapy`, `BCST benefits`, `Benefits of Biodynamic Craniosacral Therapy`]}
        description="Biodynamic Craniosacral Therapy (BCST) is a gentle therapy due to its non-invasive, non-manipulative nature. It is a therapy that focuses on the health in your system and facilitating the body to self repair."
        title="Women's Health, Babies, and Children"
      />

      <WomensHealthAndChildren />
    </Layout>
  );
}

export default WomensHealthAndChildrenPage;
