import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

function WomensHealthAndChildren() {
  const { wildTime, motherAndBaby, motherAndBaby2, child } = useStaticQuery(graphql`
    query {
      wildTime: file(relativePath: { eq: "wild-time.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      motherAndBaby: file(relativePath: { eq: "baby.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      motherAndBaby2: file(relativePath: { eq: "baby-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      child: file(relativePath: { eq: "child.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className="px-8 md:px-16 lg:max-w-screen-lg mx-auto">
      <h1 className="my-8 md:my-12 lg:my-16 font-semibold text-lg md:text-2xl lg:text-3xl text-gray-800 tracking-wider text-center">
        Women&apos;s Health, Babies, & Children
      </h1>

      <Img
        className="my-6 md:my-12 mx-auto"
        style={{ maxWidth: "600px" }}
        fluid={wildTime.childImageSharp.fluid}
        alt="Ang in the woods"
      />

      <div className="mx-6 md:mx-24 my-12">
        <blockquote className="pl-4 font-serif leading-loose text-justify border-l-4 border-gray-900">
          What is the Wild Woman? She is all that is of instinct&hellip;<br />She is the voice that says, &quot;This way, this way.&quot;
        </blockquote>
        <cite className="block mt-4 text-xs font-bold text-right uppercase">
          - Clarissa Pinkola Estes
        </cite>
      </div>

      <div className="my-12">
        <h4 className="mt-8 mb-4 md:mt-12 md:mb-4 lg:mt-16 lg:mb-4 font-semibold text-lg md:text-2xl lg:text-3xl text-gray-800 tracking-wider">
          Women&apos;s Health
        </h4>

        <p className="mt-4">
          When diagnosed with Triple Negative Breast Cancer in 2013 I knew that I could <strong>focus on healing vs fighting to survive.</strong> I deeply trust the wisdom of my body and began asking my body the questions “What is really going on here?” and “What do you need to heal?” The answers I received helped me navigate this challenging time and led me to BCST for support after cancer treatment. BCST supported my nervous system to heal, brought me into a new relationship with Health, and I regained a sense of my vital self.
        </p>

        <p className="mt-4">
          Then after birthing my my son in 2015, I struggled as a new mother with breastfeeding, mood changes, anxiety, lack of sleep, and overwhelm. BCST is what supported my wellness, allowed me to co-regulate with my little one, and find more ease in motherhood.
        </p>

        <p className="mt-4">
          For women who are on a healing journey: I’ve been in your shoes and I’m here to walk with you. I understand how challenging and even overwhelming it can be to navigate diagnosis, information, choices, and treatments. Mama, I know how hard we can be on ourselves when we find we are not coping well. Whatever challenge you are facing, I know that Health is available in your system and that BCST will be supportive of bringing you into new relationship with it. The power of nervous system regulation and trauma resolution to support healing and wellness never stops to amaze me and has shaped me to be deeply passionate about this work!
        </p>
      </div>

      <div className="mt-12 mb-12 clear-left">
        <h4 className="mt-8 mb-4 md:mt-12 md:mb-4 lg:mt-16 lg:mb-4 font-semibold text-lg md:text-2xl lg:text-3xl text-gray-800 tracking-wider">
          Fertility Journey and Motherhood
        </h4>

        <p className="mt-4">
          For women that desire to conceive, BCST helps your physical form find optimal organization and supports psycho-emotional resolution to come through that may be creating barriers to conception. Supporting nervous system regulation to move from high-alert to a more calmer, flexible state helps women to feel safe, embodied, and more fully connected to their sexuality/desire.
        </p>

        <p className="mt-4">
          For women that are pregnant, BCST can be vital in improving comfort and wellbeing in a time of rapid change. It assists in releasing restrictions, tension, and pain and supports organization for optimal positioning of the little one as both of you prepare for birth. Nervous system regulation can help with resolution of fears or previous childbirth trauma so that you can approach birthing with strength, confidence, and vitality.
        </p>

        <Img
          className="my-6 md:my-12 mx-auto"
          style={{ maxWidth: "600px" }}
          fluid={motherAndBaby.childImageSharp.fluid}
          alt="Mother with baby"
        />

        <p className="mt-4">
          Babies respond really well to BCST, and it is gentle enough for newborns just days old due to its non-invasive, non-manipulative nature. Those that have had a premature, caesarean, difficult, or medically assisted birth benefit greatly from this therapy. Arriving Earthside can include experiences of compression, restriction, and even overwhelm. Often these experiences can be expressed as:
        </p>

        <ul className="list-disc ml-8 mt-4">
          <li>Colic</li>
          <li>Reflux</li>
          <li>Constipation</li>
          <li>Tight groin</li>
          <li>Torticollis</li>
          <li>Irregular head shape</li>
          <li>Difficulty settling</li>
          <li>Difficulty connecting</li>
          <li>Distress/crying</li>
          <li>Sleep issues</li>
          <li>Breastfeeding challenges</li>
        </ul>

        <p className="mt-4">
          Essentially BCST is a way to ask your baby “What’s going on for you/what do you need?” BCST creates a sense of safety for your little one&apos;s system to express the patterns it&apos;s holding and find resolution; it is also respectful and empowering for little ones as they are acknowledged as conscious and very aware. The therapy is led by their body, allowing their system to set the pace at which adjustments take place and the order in which change occurs. How wonderful it is to be deeply listened to, imagine how supportive it could be for a little one to be acknowledged this way through biodynamic touch!
        </p>

        <Img
          className="my-6 md:my-12 mx-auto"
          style={{ maxWidth: "600px" }}
          fluid={motherAndBaby2.childImageSharp.fluid}
          alt="Mother with baby"
        />

        <p className="mt-4">
          Babies really are proof of this work as they have not acquired knowledge about the therapy and respond remarkably well. In session it is often obvious that the little one is engaged with the therapeutic process and parents can see their little one shifting, softening and settling.
        </p>

        <p className="mt-4">
          In the fourth trimester, BCST supports BOTH yours and your little one&apos;s system TOGETHER. This therapy focus on the whole of things and opens up to the relational nature of Life; this means moms and babes are acknowledged and treated as one unit with postpartum care of mama being equally important! I meet the baby&apos;s system AND support how mom&apos;s nervous system is coping. This results in nervous system regulation for both which allows for more ease in your overall function together. In session this looks like placing hands on both mom and babe and listening to the priority between the two. This can lead to hands on just baby, hands on just mom, or hands on both together. This can lead to some improvisation in positioning of moms and babies, however comfort is the priority. There is no need to lie still and the little ones are held by their their parent while receiving treatment. We encourage moms to do everything and anything that supports their comfort during the session including moving about/bouncing, playing, even breast/feeding. Partners can also benefit from BCST by having space to process and integrate their experiences too.
        </p>
      </div>

      <div className="my-12">
        <Img
          className="my-6 md:my-12 mx-auto"
          style={{ maxWidth: "600px" }}
          fluid={child.childImageSharp.fluid}
          alt="Child"
        />
        <h4 className="mt-8 mb-4 md:mt-12 md:mb-6 lg:mt-16 lg:mb-8 font-semibold text-lg md:text-2xl lg:text-3xl text-gray-800 tracking-wider">
          Young Children
        </h4>

        <p className="mt-4">
          Young children respond really well to BCST. In session I create a sense of safety by giving the little ones the power to dictate when I can touch and when I cannot. I have experienced children loving the empowerment the session gives them, showing me exactly where they would like to be touched, when they need a break to integrate and then when they&apos;re ready to be touched again. Something profound happens when their system is acknowledged as intelligent with its own priorities AND they themselves are met the same way too.
        </p>

        <p className="mt-4">
          Often parents will bring their children for a session because of symptoms or concerns, but what the child ends up getting out of the session is a deep acknowledgement of their existence, their awareness, their story, their power and their autonomy.
        </p>
      </div>
    </div>
  );
}

export default WomensHealthAndChildren;
